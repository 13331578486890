<template>
    <div class="m-wrapper04">
        <div class="m-modular07 m-cancel">
            <img src="../../assets/images/icon-cg.png" class="icon-success01">
            <p class="success-tit01">{{status == 3 ? '预约已取消' : '预约成功'}}</p>
        </div>
        <!-- 取消预约进来 -->
        <p v-if="status == 3" class="success-tip01 m-hint" >
            温馨提示:尊敬的{{userRecordInfo.name}}，您预约{{userRecordInfo.commandTime}}
            {{userRecordInfo.hospitalName}}{{userRecordInfo.departmentName}}{{userRecordInfo.doctorName}}已退号成功。感谢您的使用!
        </p>
        <p v-if="status == 2" class="success-tip01 m-hint" >
              您已成功预约福建省人民医院消化科林瑜医生的门诊，
              就诊时间: 2021年6月39日星期三上午10:30 (19号)，
              就诊当日请提前30分钟到医院自助机取号，迟到15 分钟号码作废，
              医院地址：福建省福州市台江区八-七中路6号，
              联系电话: 0591-87557768,谢谢!
        </p>
        <a @click="watchRecord" class="return-btn01">查看{{status == 3 ? '退号' : '预约'}}记录</a>
    </div>
</template>
<script>
export default {
    data() {
        return {
            status: '',
            userRecordInfo: {},
            storeRecordList: []
        }
    },
    mounted(){
        this.storeRecordList = this.$store.state['mineRecord']['storeRecordList']
        let queryAppointId = this.$route.query['appointId']
        this.userRecordInfo = (this.storeRecordList.filter(item => {
            return item.appointId == queryAppointId
        }))[0]
        console.log('this.userRecordInfo,', this.userRecordInfo)
        this.status = this.$route.params['status']
    },
    methods: {
        watchRecord() {
            this.$router.push(`/appointmentInfo/${this.$route.params['status']}`)
        }
    }
}
</script>